import React from "react";
import RestaurantList from "./RestaurantList";

const MainRes = () => {
  return (
    <div>
      <RestaurantList />
    </div>
  );
};

export default MainRes;
